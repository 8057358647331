import React from "react";
import WIOSSloadingAnimation from "./WIOSS"; // Adjust the path as necessary

function App() {
  const handleOnClick = () => {
    window.location.href = "https://vinterbro.wioss.no";
  };

  return (
    <div className="App">
      <WIOSSloadingAnimation onClick={handleOnClick} />
    </div>
  );
}

export default App;
