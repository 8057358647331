import ParticleImage, { forces } from "react-particle-image";

const particleOptions = {
  filter: ({ x, y, image }) => {
    const pixel = image.get(x, y);
    // Adjust these tolerances as needed to more accurately capture your colors
    const isBlue =
      Math.abs(pixel.r - 0) < 10 &&
      Math.abs(pixel.g - 102) < 10 &&
      Math.abs(pixel.b - 180) < 10;
    const isGrey =
      Math.abs(pixel.r - 148) < 10 &&
      Math.abs(pixel.g - 150) < 10 &&
      Math.abs(pixel.b - 153) < 10;
    return isBlue || isGrey;
  },
  color: ({ x, y, image }) => {
    const pixel = image.get(x, y);
    if (
      Math.abs(pixel.r - 0) < 10 &&
      Math.abs(pixel.g - 102) < 10 &&
      Math.abs(pixel.b - 180) < 10
    ) {
      return "#0066b4"; // Blue particles
    } else {
      return "#949699"; // Grey particles
    }
  },
  radius: () => Math.random() * 1.5 + 0.5,
  mass: () => 80,
  friction: () => 0.95,
};

const screen = {
  height: "100vh",
  width: "100vw",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center", // centers horizontally in flex container
  alignItems: "center", // centers vertically in flex container
  background: "black",
};

const motionForce = (x, y) => {
  return forces.disturbance(x, y, 50);
};

const particleContainer = {
  position: "relative", // Set the particle container to relative
  width: "1200px", // Set a fixed width
  height: "500px", // Set a fixed height
};

function WIOSSloadingAnimation({ onClick }) {
  return (
    <div style={screen}>
      <div style={particleContainer} onClick={onClick}>
        <ParticleImage
          src={process.env.PUBLIC_URL + "/images/WIOSSLogo.png"} // Path to your image
          width={1200} // Width of the canvas
          height={500} // Height of the canvas
          scale={0.3} // Image scale, for better resolution increase the number
          entropy={100} // The amount of movement
          maxParticles={10000}
          particleOptions={particleOptions}
          backgroundColor="transparent"
          mouseMoveForce={motionForce}
          mouseMoveForceDuration={500}
        />
      </div>
    </div>
  );
}

export default WIOSSloadingAnimation;
